import React from "react"
import Seo from "../components/seo"
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  padding-top: 128px;
  background-color: #14062B;
  color: whitesmoke;
  text-align: center;
`;

const NotFoundPage = () => (
  // <Layout location="/404/">
  <Wrapper>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Wrapper>
  // </Layout>
)

export default NotFoundPage;
